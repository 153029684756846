import styled from "@emotion/styled";
import { Typography, useTheme } from "@mui/material";
import React from "react";

function MeneTypography({ size, children, textAlign, fontWeight, color }) {
  const theme = useTheme();
  return (
    <MeneText
      $color={color || theme.palette.text.primary}
      $fontSize={size === "large" ? "36px" : "18px"}
      $fontWeight={fontWeight === "large" ? "bold" : "normal"}
      $textAlign={textAlign || "flex-start"}
    >
      {children}
    </MeneText>
  );
}

const MeneText = styled(Typography)((props) => ({
  color: props.$color,
  fontSize: props.$fontSize,
  fontWeight: props.$fontWeight,
  textAlign: props.$textAlign,
}));

export default MeneTypography;
