import React from "react";
import "./MovingLine.css"; // Import the CSS file, make sure the path is correct

function MovingLine({ width, height, top, left, x1, x2, y1, y2, stroke }) {
  return (
    <div
      style={{
        width: width,
        height: "100%",
        overflow: "scroll",
        position: "absolute",
        top: top,
        zIndex: 0,
      }}
    >
      <svg width="100%" height="100%">
        <line id="line" x1={x1} y1={y1} x2={x2} y2={y2} stroke="white" />
      </svg>
    </div>
  );
}

export default MovingLine;
