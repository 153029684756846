import { Typography } from "@mui/material";
import { Navigate, useRoutes } from "react-router-dom";
import MainPage from "./pages/MainPage";
import ErrorPage from "./pages/ErrorPage";
import LogoOnlyLayout from "./components/LogoOnlyLayout";
import ProjectsPage from "./pages/ProjectsPage";
import ResumePage from "./pages/ResumePage";
import FindingsPage from "./pages/FindingsPage";
import MiscPage from "./pages/MiscPage";

// ----------------------------------------------------------------------
// element: uid ? <DashboardLayout /> : <Navigate to="/login" />,
export default function Router() {
  return useRoutes([
    {
      path: "/",

      element: <LogoOnlyLayout />,
      children: [
        { element: <Navigate to="/" replace /> },
        { path: "/", element: <MainPage /> },
        { path: "projects", element: <ProjectsPage /> },
        { path: "resume", element: <ResumePage /> },
        { path: "findings", element: <FindingsPage /> },
        { path: "misc", element: <MiscPage /> },
      ],
    },
    { path: "*", element: <Typography>IN TROUBLE</Typography> },
  ]);
}
