import { Box, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import Container from "../components/Container";
import styled from "@emotion/styled";
import MeneTypography from "../glovebox/MeneTypography";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { motion } from "framer-motion";
import { CheckIcon } from "../utils/icons/CheckIcon";
import MovingLine from "../components/MovingLine";
import HoldMovingLines from "../components/HoldMovingLines";
import HoldCornerLines from "../components/HoldCornerLines";
import MovingLine2 from "../components/MovingLine2";
import CryptographicString from "../components/CryptographicString";
import Tab from "../glovebox/Tab";
import EmailLogo from "../assets/images/Email.png";
import LinkedinLogo from "../assets/images/LinkedIn.png";
import TwitterLogo from "../assets/images/Twitter.png";
import { LinkedIn } from "../utils/icons/LinkedIn";
import { Twitter } from "../utils/icons/Twitter";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";

function MainPage() {
  const [organize, setOrganize] = useState(false);
  const theme = useTheme();
  const images = [EmailLogo, LinkedinLogo, TwitterLogo];

  return (
    <Container>
      <CenterBox>
        <div
          style={{
            width: "50%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <MeneTypography size={"large"} textAlign={"center"}>
            Mene Mazarakis
          </MeneTypography>
        </div>
        <div
          style={{
            width: "50%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            gap: "8px",
          }}
        >
          <CryptographicString
            target="Current status: "
            size={"small"}
            fontWeight={"large"}
            color={theme.palette.text.secondary}
          />

          <CryptographicString
            target="tinkering with ideas in the national security & defense space"
            size={"small"}
            color={theme.palette.text.secondary}
          />
        </div>
        {organize ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "12px",
              position: "absolute",
              bottom: 75,
              zIndex: 1,
            }}
          >
            <Tab link={"/projects"}>Projects</Tab>
            <Tab link={"/resume"}>Resume</Tab>
            <Tab link={"/findings"}>Findings</Tab>
            <Tab link={"/misc"}>Misc</Tab>
          </div>
        ) : null}
        {organize ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "24px",
              position: "absolute",
              left: 50,
              zIndex: 1,
            }}
          >
            <a href="https://www.linkedin.com/in/menelaosm/">
              <LinkedIn
                active={true}
                clicked={() => {}}
                height={"48"}
                width={"48"}
              />
            </a>

            <a href="https://twitter.com/menemazarakis">
              <Twitter
                active={true}
                clicked={() => {}}
                height={"48"}
                width={"48"}
              />
            </a>

            <MailOutlineRoundedIcon
              onClick={() => {
                window.open(`mailto:mgmazarakis@gmail.com`);
              }}
              sx={{
                color: theme.palette.primary.main,
                width: "28px",
                height: "28px",
                backgroundColor: theme.palette.secondary.light,
                borderRadius: "4px",
                padding: "4px",
                margin: 0,
                "&:hover": {
                  margin: 0,
                  cursor: "pointer",
                },
              }}
            />
          </div>
        ) : null}

        {/* <MovingLine2 organize={organize} top={-200} />
        <MovingLine2 organize={organize} top={200} /> */}

        <motion.div
          // whileTap={{ rotate: [90, 0], scale: [1, 1.1, 1] }}
          // whileHover={{ rotate: 10 }}
          whileInView={{
            rotate: organize ? [0] : [2, -2, 0],
            scale: organize ? [1] : [1, 1.05, 1],
          }}
          transition={{
            type: "spring",
            stiffness: 100,
            damping: 8,
          }}
          id="check-box"
          style={{
            marginRight: "12px",
            alignSelf: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "12px",
            zIndex: 1,
          }}
          onClick={() => {
            setOrganize(!organize);
          }}
        >
          <CheckIcon active={organize} clicked={() => {}} />
        </motion.div>
      </CenterBox>
      <HoldMovingLines />
      <HoldCornerLines organize={organize} />
    </Container>
  );
}

const CenterBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

// const UpperLeftVertical = styled(motion.div)`
//   border-radius: 6px;
//   height: 100px;
//   width: 10px;
//   top: 0;
//   left: 0;
//   background-color: white;
//   margin: 20px;
// `;

const MovingDottedLine = styled.div`
  stroke-dasharray: 10px 5px;
  stroke-width: 100px;
  stroke: gray;
  stroke-linecap: round;
  stroke-linejoin: round;
`;

const UpperLeftHorizontal = styled(Box)`
  position: absolute;
  border-radius: 6px;
  height: 10px;
  width: 100px;
  top: 0;
  left: 0;
  background-color: white;
  margin: 20px;
`;

const BottomLeftPlusSign = styled(AddRoundedIcon)`
  position: absolute;
  bottom: 0;
  left: 0;
  color: white;
  font-weight: lighter;
  font-size: 100px;
  margin: 20px;
`;

const UpperRightPlusSign = styled(AddRoundedIcon)`
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  font-weight: lighter;
  font-size: 100px;
  margin: 20px;
`;

const BottomRightPlusSign = styled(AddRoundedIcon)`
  position: absolute;
  bottom: 0;
  right: 0;
  color: white;
  font-weight: lighter;
  font-size: 100px;
  margin: 20px;
`;

export default MainPage;
