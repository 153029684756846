import React, { useEffect, useState } from "react";
import MeneTypography from "../glovebox/MeneTypography";

const getRandomChar = () =>
  String.fromCharCode(65 + Math.floor(Math.random() * 26)).toLowerCase();

function CryptographicString({ target, size, fontWeight, color }) {
  const [displayString, setDisplayString] = useState(
    Array(target.length).fill(" ")
  );

  useEffect(() => {
    let timer = 0;
    for (let i = 0; i < target.length; i++) {
      setTimeout(() => {
        for (let j = 0; j < 3; j++) {
          setTimeout(() => {
            setDisplayString((prev) => {
              const newArr = [...prev];
              newArr[i] = getRandomChar();
              return newArr;
            });
          }, j * 100);
        }
        setTimeout(() => {
          setDisplayString((prev) => {
            const newArr = [...prev];
            newArr[i] = target[i];
            return newArr;
          });
        }, 300);
      }, timer);
      timer += 50;
    }
  }, [target]);

  return (
    <MeneTypography
      size={size}
      textAlign={"center"}
      fontWeight={fontWeight}
      color={color}
    >
      {displayString}
    </MeneTypography>
  );
}

export default CryptographicString;
