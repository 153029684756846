import { Box, Typography, styled, useTheme } from "@mui/material";
import React from "react";
import MeneTypography from "./MeneTypography";
import { HoverPointerStyle } from "../utils/icons/HoverPointerStyle";
import { Link } from "react-router-dom";

function Tab({ children, link }) {
  const theme = useTheme();

  return (
    <Link to={link}>
      <Box
        sx={{
          backgroundColor: theme.palette.primary.light,
          borderRadius: "8px",
          padding: "8px",
          color: theme.palette.text.primary,
          zIndex: 1,
          margin: "8px",
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        {children}
      </Box>
    </Link>
  );
}

export default Tab;
