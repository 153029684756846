import React from "react";
import MovingLine from "./MovingLine";
import Container from "./Container";

function HoldMovingLines() {
  return (
    <Container>
      <MovingLine
        width={"100vw"}
        height={"100vh"}
        top={150}
        x1="0"
        y1="50%"
        x2="100%"
        y2="50%"
      />
      <MovingLine
        width={"100vw"}
        height={"100vh"}
        top={-150}
        x1="0"
        y1="50%"
        x2="100%"
        y2="50%"
      />
      <MovingLine
        width={"100vw"}
        height={"100vh"}
        top={0}
        left={100}
        x1="20%"
        y1="0"
        x2="20%"
        y2="100%"
      />
      <MovingLine
        width={"100vw"}
        height={"100vh"}
        top={0}
        left={100}
        x1="80%"
        y1="0"
        x2="80%"
        y2="100%"
      />
    </Container>
  );
}

export default HoldMovingLines;
