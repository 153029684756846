import { useTheme } from "@mui/material";
import React from "react";

function Container({ children }) {
  const theme = useTheme();
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        overflow: "scroll",
        backgroundColor: theme.palette.primary.main,
      }}
    >
      {children}
    </div>
  );
}

export default Container;
