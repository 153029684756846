import logo from "./logo.svg";
import "./App.css";
import CornerLines from "./components/CornerLines";
import { ThemeProvider, createTheme, useTheme } from "@mui/material";
import { useState } from "react";
import { PageContext, ThemeContext } from "./utils/contexts";
import Router from "./route";
import { Helmet, HelmetProvider } from "react-helmet-async";
import styled from "styled-components";

function App() {
  const [themeValue, setThemeValue] = useState("gray");
  const localTheme = localStorage.getItem("theme");
  const [page, setPage] = useState("Schedule");

  const theme = useTheme();

  const setThemeValueToLight = () => {
    setThemeValue("light");
  };

  const setThemeValueToGray = () => {
    setThemeValue("gray");
  };

  const setThemeValueToDark = () => {
    setThemeValue("dark");
  };
  return (
    <Wrapper>
      <HelmetProvider>
        <ThemeContext.Provider value={{ themeValue, setThemeValue }}>
          <ThemeProvider
            theme={
              localTheme === "light"
                ? lightTheme
                : localTheme === "dark"
                ? darkTheme
                : grayTheme
            }
          >
            <PageContext.Provider value={{ page, setPage }}>
              <Helmet title={`Mene Portfolio | ${page}`}></Helmet>

              <Router />
            </PageContext.Provider>
          </ThemeProvider>
        </ThemeContext.Provider>
      </HelmetProvider>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #232529;
  overflow: hidden;
  canvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 0;
  }
`;

const lightTheme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "& +.MuiInputBase-root": {
            marginTop: 0,
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#FAFBFB",
      light: "#e7e8ea",
      dark: "#FFFFFF",
    },
    secondary: {
      light: "#d7d8db",
      dark: "#777777",
      main: "#000000",
    },
    background: {
      default: "#ffffff",
    },
    text: {
      primary: "#000000",
      secondary: "#888",
    },
    action: {
      active: "#1e88a3",
    },
    warning: {
      light: "#78FBB4",
      main: "#6384F7",
    },
    error: {
      main: "#E74959",
      light: "#F19E4B",
      dark: "#371A18",
    },
    success: {
      main: "#30D08D",
      light: "#30D08D",
    },
  },
});

const grayTheme = createTheme({
  palette: {
    primary: {
      main: "#232529",
      light: "#30343C",
      dark: "#666666",
    },
    secondary: {
      light: "#3E4249",
      main: "#ffffff",
    },
    background: {
      default: "#cccccc",
    },
    text: {
      primary: "#ffffff",
      secondary: "#777",
    },
    warning: {
      light: "#78FBB4",
      main: "#6384F7",
    },
    error: {
      main: "#FFAAAA",
      light: "#F19E4B",
      dark: "#E74959",
    },
    action: {
      active: "#3AC4E8",
    },
    success: {
      main: "#30D08D",
      light: "#233633",
    },
  },
});

const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#000000",
      light: "#333333",
      dark: "#222222",
    },
    secondary: {
      light: "#222222",
      main: "#ffffff",
    },
    background: {
      default: "#ffffff",
    },
    warning: {
      light: "#78FBB4",
      main: "#6384F7",
    },
    error: {
      main: "#E74959",
      light: "#F19E4B",
      dark: "#371A18",
    },
    text: {
      primary: "#ffffff",
      secondary: "#777",
    },
    action: {
      active: "#3AC4E8",
    },
    success: {
      main: "#30D08D",
      light: "#233633",
    },
  },
});

export default App;
