import React from "react";
import { motion } from "framer-motion";

const PLUS_SIGN_WIDTH = "4px";
const PLUS_SIGN_BORDER_RADIUS = "6px";

function HoldCornerLines({ organize }) {
  return (
    <div>
      {" "}
      <motion.div
        style={{
          backgroundColor: "white",
          borderRadius: PLUS_SIGN_BORDER_RADIUS,
          position: "absolute",
          top: 0,
          left: 0,
          margin: 20,
          width: PLUS_SIGN_WIDTH,
          height: "50px",
        }}
        whileInView={{ x: organize ? 22.5 : 0 }}
      />
      <motion.div
        style={{
          backgroundColor: "white",
          borderRadius: PLUS_SIGN_BORDER_RADIUS,
          position: "absolute",
          top: 0,
          left: 0,
          margin: 20,
          width: "50px",
          height: PLUS_SIGN_WIDTH,
        }}
        whileInView={{ y: organize ? 22.5 : 0 }}
      />
      <motion.div
        style={{
          backgroundColor: "white",
          borderRadius: PLUS_SIGN_BORDER_RADIUS,
          position: "absolute",
          bottom: 0,
          left: 0,
          margin: 20,
          width: PLUS_SIGN_WIDTH,
          height: "50px",
        }}
        whileInView={{ x: organize ? 22.5 : 0 }}
      />
      <motion.div
        style={{
          backgroundColor: "white",
          borderRadius: PLUS_SIGN_BORDER_RADIUS,
          position: "absolute",
          bottom: 0,
          left: 0,
          margin: 20,
          width: "50px",
          height: PLUS_SIGN_WIDTH,
        }}
        whileInView={{ y: organize ? -22.5 : 0 }}
      />
      <motion.div
        style={{
          backgroundColor: "white",
          borderRadius: PLUS_SIGN_BORDER_RADIUS,
          position: "absolute",
          top: 0,
          right: 0,
          margin: 20,
          width: PLUS_SIGN_WIDTH,
          height: "50px",
        }}
        whileInView={{ x: organize ? -22.5 : 0 }}
      />
      <motion.div
        style={{
          backgroundColor: "white",
          borderRadius: PLUS_SIGN_BORDER_RADIUS,
          position: "absolute",
          top: 0,
          right: 0,
          margin: 20,
          width: "50px",
          height: PLUS_SIGN_WIDTH,
        }}
        whileInView={{ y: organize ? 22.5 : 0 }}
      />
      <motion.div
        style={{
          backgroundColor: "white",
          borderRadius: PLUS_SIGN_BORDER_RADIUS,
          position: "absolute",
          bottom: 0,
          right: 0,
          margin: 20,
          width: PLUS_SIGN_WIDTH,
          height: "50px",
        }}
        whileInView={{ x: organize ? -22.5 : 0 }}
      />
      <motion.div
        style={{
          backgroundColor: "white",
          borderRadius: PLUS_SIGN_BORDER_RADIUS,
          position: "absolute",
          bottom: 0,
          right: 0,
          margin: 20,
          width: "50px",
          height: PLUS_SIGN_WIDTH,
        }}
        whileInView={{ y: organize ? -22.5 : 0 }}
      />
    </div>
  );
}

export default HoldCornerLines;
